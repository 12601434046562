.case-study-description {
  margin: 0 40px;
}

.testimonial-author {
  text-align: center;
}

.testimonial-author h5 {
  margin-top: 16px;
}

.testimonial-author__img {
  width: 170px;
  height: 170px;
  border-radius: 50%;
  border: 4px solid rgba(0, 0, 0, 0.1);
}
